import React, { ReactNode, useState } from "react";

export const AppContext = React.createContext<{
    currentPage: String;
    targetPage: String;
    setCurrentPage: (currentPage: String) => void;
    setTargetPage: (currentPage: String) => void;
}>({} as any);

export default function Wrapper({ children }: { children: ReactNode }) {
    const [currentPage, setCurrentPage] = useState<String>("");
    const [targetPage, setTargetPage] = useState<String>("");

    return (
        <AppContext.Provider
            value={{ currentPage, setCurrentPage, targetPage, setTargetPage }}
        >
            {children}
        </AppContext.Provider>
    );
}
