import useScrollintoView from "../hooks/useScrollintoView";

export function Contact() {
    const headerRef = useScrollintoView("#contact", 0.9);
    return (
        <div
            ref={headerRef}
            className="flex justify-center items-center section p-3 text-xl h-[50vh]"
            id="contact"
        >
            <p className="text-center">
                Let's build something together,{" "}
                <a
                    className="text-warm-green animate-bounce inline-block"
                    href={`mailto:dev.woodhouse@gmail.com?subject='Let's work together'`}
                >
                    shoot me an email
                </a>
            </p>
        </div>
    );
}
