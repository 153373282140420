import { useContext, useEffect, useRef } from "react";
import { isInTop } from "../helpers/isInTop";
import { AppContext } from "../context";

export default function useScrollintoView(href: string = "", threshold = 0.2) {
    const ref = useRef<HTMLHeadingElement>(null!);
    const { currentPage, targetPage, setTargetPage, setCurrentPage } =
        useContext(AppContext);

    console.log(currentPage);
    useEffect(() => {
        const node = ref.current;
        if (href && node) {
            const onScroll = () => {
                if (isInTop(node, threshold)) {
                    const url = new URL(window.location.href);

                    url.hash = href;

                    if (targetPage === href) {
                        setTargetPage("");
                        // window.location.hash = href;
                        window.history.replaceState(null, "", url);
                        setCurrentPage(href);
                    } else if (!targetPage && currentPage !== href) {
                        // window.location.hash = href;
                        window.history.replaceState(null, "", url);
                        setCurrentPage(href);
                    }
                }
            };

            window.addEventListener("scroll", onScroll);

            return () => {
                window.removeEventListener("scroll", onScroll);
            };
        }
    }, [ref, currentPage, targetPage, setTargetPage, href]);

    return ref;
}
