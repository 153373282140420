import React, { useContext, useEffect, useRef, useState } from "react";
import IonIcon from "@reacticons/ionicons";
import { twJoin } from "tailwind-merge";
import { ReactComponent as LinkedIn } from "./components/linkedin original.svg";
import { AppContext } from "./context";

export function Header() {
    const [loaded, setLoaded] = useState(false);
    const menuRef = useRef<HTMLDivElement>(null);
    const ulRef = useRef<HTMLUListElement>(null);
    const { currentPage, targetPage, setCurrentPage, setTargetPage } =
        useContext(AppContext);
    // const [currentPage, setCurrentPage] = useState("");
    const [name, setName] = useState<"close" | "menu">("menu");
    const toggleMenu = () => {
        if (name === "menu") {
            setName("close");
        } else {
            setName("menu");
        }
    };

    const onClickLink = (e: React.MouseEvent<HTMLAnchorElement>) => {
        const link = e.currentTarget.getAttribute("href") || "";

        if (targetPage !== currentPage) setTargetPage(link);
    };

    const handleURLChange = () => {
        setCurrentPage(window?.location?.href?.split("/")?.slice(-1)?.[0]);
    };

    useEffect(() => {
        setCurrentPage(window.location.hash || currentPage);
    }, []);

    useEffect(() => {
        window.addEventListener("popstate", handleURLChange);
        return () => window.removeEventListener("popstate", handleURLChange);
    }, []);

    useEffect(() => {
        setLoaded(true);
    }, []);

    return (
        <div
            className={twJoin(
                "fixed left-0 right-0 z-30 bg-backgroundd bg-bg-2 px-3 py-2 animate-[fade-in_6s_ease-in]",
                name === "close" ? "bg-opacity-100" : "bg-opacity-25"
            )}
            ref={menuRef}
        >
            <div className="w-full max-w-[1024px] mx-auto md:flex md:items-center md:justify-between">
                <div className="flex items-center justify-between">
                    <a
                        className="block font-bold font-family-[poppins]"
                        href="#"
                        onClick={onClickLink}
                    >
                        GemFromJa
                    </a>

                    <div className="md:hidden cursor-pointer">
                        <IonIcon
                            name={name}
                            onClick={toggleMenu}
                            size="large"
                        />
                    </div>
                </div>
                <ul
                    ref={ulRef}
                    className={`md:flex md:justify-end md:w-auto md:py-0 pb-4 md:pl-0 sm:pl-7 md:opacity-100 bgh-backgroundd bg-bg-2 sm:bg-transparent md:static absolute w-full left-0 transition-all ease-in duration-500 ${
                        name === "close"
                            ? `top-[${
                                  menuRef.current?.clientHeight || 53
                              }px] opacity-100`
                            : "top-[-400px] opacity-0"
                    }`}
                >
                    {/* <a
                    className={`block hover:text-warm-green ${
                        currentPage === "#about" ? "text-warm-green font-semibold" : ""
                    }`}
                    href="#about"
                >
                    <li className="px-3 py-2 mr-2">Intro</li>
                </a> */}
                    <a
                        className={`block hover:text-warm-green ${
                            currentPage === "#about"
                                ? "text-warm-green font-semibold"
                                : ""
                        }`}
                        href="#about"
                        onClick={onClickLink}
                    >
                        <li className="px-3 py-2 mr-2">About</li>
                    </a>
                    <a
                        className={`block hover:text-warm-green ${
                            currentPage === "#skills"
                                ? "text-warm-green font-semibold"
                                : ""
                        }`}
                        href="#skills"
                        onClick={onClickLink}
                    >
                        <li className="px-3 py-2 mr-2">Skills</li>
                    </a>
                    <a
                        className={`block hover:text-warm-green ${
                            currentPage === "#projects"
                                ? "text-warm-green font-semibold"
                                : ""
                        }`}
                        href="#projects"
                        onClick={onClickLink}
                    >
                        <li className="px-3 py-2 mr-2">Projects</li>
                    </a>
                    <a
                        className={`block hover:text-warm-green ${
                            currentPage === "#contact"
                                ? "text-warm-green font-semibold"
                                : ""
                        }`}
                        href="#contact"
                        onClick={onClickLink}
                    >
                        <li className="px-3 py-2 mr-2">Contact</li>
                    </a>
                    <a
                        href="https://medium.com/@gemfromja"
                        target="_blank"
                        rel="noopener"
                        className={`block hover:text-warm-green`}
                    >
                        <li className="px-3 py-2 mr-2">Blog</li>
                    </a>
                    {/* <a
                        href="https://www.linkedin.com/in/stephan-woodhouse"
                        target="_blank"
                        rel="noopener"
                        className={`block hover:text-warm-green`}
                    >
                        <li className="px-3 py-2 mr-2 ">
                            <LinkedIn className="h-[20px] w-[20px] hidden sm:block" />
                            <span className="sm:hidden">Linkedin</span>
                        </li>
                    </a> */}
                </ul>
            </div>
        </div>
    );
}
