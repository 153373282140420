import React, { Suspense, useContext, useEffect, useState } from "react";
import { Header } from "./Header";
import { ReactComponent as DownArrow } from "./assets/down-arrows.svg";
import useScrollintoView from "./hooks/useScrollintoView";
import { AppContext } from "./context";
import { Contact } from "./components/Contact";
import "./App.css";

const BGScene = React.lazy(() => import("./components/background/BGScene"));
const Experience = React.lazy(() => import("./components/Experience"));
const Skills = React.lazy(() => import("./components/skills"));
const Projects = React.lazy(() => import("./components/Projects"));

function App() {
    const { setTargetPage } = useContext(AppContext);
    const headerRef = useScrollintoView("#landing", 0.5);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (!loaded) setLoaded(true);
    }, []);

    function onClickArrow() {
        const link = "#about";
        setTargetPage(link);
        window.location.hash = link;
    }

    return (
        <div className="App bg-backgroundd bg-black relative w-full">
            <div className="fixed top-0 z-0 h-[100vh] w-[100vw]">
                {/* <div className="absolute top-0  h-full w-full bg-black opacity-90" /> */}
                <Suspense fallback={<></>}>
                    {/* <BGVideo /> */}
                    <BGScene />
                </Suspense>
            </div>
            <div className="absolute w-full">
                {/** max-width: 988px */}
                {/* Menu bar */}
                <Header />
                {/* Hero section */}
                <div className="h-[100vh] w-full overflow-hidden">
                    <div
                        id="landing"
                        className="section h-full w-full relative z-10 flex flex-col items-center justify-center"
                    >
                        <div className="m-full max-w-[800px] my-auto">
                            <div
                                className="px-3 my-auto font-star-wars animate-[font-load_3.5s] text-star-wars-yellow text-4xl sm:text-8xl text-warm-greeen font-[800] flex flex-col cursor-pointer"
                                ref={headerRef}
                            >
                                <p className="self-center sm:self-start sm:text-9xl text-5xl">
                                    Stephan
                                </p>
                                <p className="self-center sm:self-start text-4xl sm:text-8xl">
                                    Woodhouse
                                </p>
                            </div>
                            <h3 className="text-center sm:text-left px-3 mt-4 font-star-wars-full text-3xl w-full max-w-[800px] animate-[fade-in_7s_ease-in,pulse_2.5s_infinite_5s] text-star-wars-yellow">
                                Fullstack Developer{" "}
                            </h3>
                        </div>

                        <DownArrow
                            className="animate-[fade-in_7s_ease-in,bounce_1s_infinite_1s] mt-auto absolute bottom-[48px] sm:bottom-[24px] mx-auto w-[36px] h-[36px] text-white cursor-pointer"
                            color="#fff"
                            onClick={onClickArrow}
                        />
                        <div className=" bg-gradient-to-b from-transparent  to-background bg-backgroudnd w-full h-[10vh] mt-8" />
                    </div>
                </div>

                <div className=" mx-auto my-0 ">
                    <Suspense fallback="">
                        <Experience />
                    </Suspense>
                    <Suspense fallback="">
                        <Skills />
                    </Suspense>
                    <Suspense fallback="">
                        <Projects />
                    </Suspense>

                    <Contact />
                </div>
                <footer>
                    <p className="text-center my-4 text-xs">
                        Copyright © 2024 Stephan Woodhouse
                    </p>
                </footer>
            </div>
        </div>
    );
}

export default App;
